import * as React from 'react'
import { Trans } from '@lingui/macro'

import { Section } from '../../lib/components/section'
import { Link } from '../../lib/components/link'
import { useNavShortcut } from '../../lib/components/shortcuts'
import { ShortCode } from '../../lib/components/shortcode'
import { IdentifierType } from '../../lib/components/identifier'
import { useInteraction } from '../../lib/components/analytics'
import { g, e } from '../../lib/keycodes'

import css from './styles.css'
import { Edit, EllipsisH, Vinyl } from '../../lib/components/icon'

type Props = { discogsId: number; siteUrl: string }

export function ArtistActionsDesktop(props: Props): React.ReactElement {
    const { discogsId, siteUrl } = props

    const header = (
        <>
            <h3 className={css.header}>
                <Trans>Artist</Trans>
            </h3>
            <span className={css.spacer} />
            <ShortCode type={IdentifierType.Artist} discogsId={discogsId} />
        </>
    )

    return (
        <div className={css.desktopActionsContainer}>
            <Section id='artist-actions' header={header} collapsible={false}>
                <EditLink siteUrl={siteUrl} />
            </Section>
        </div>
    )
}

type EditProps = {
    siteUrl: string
}

function EditLink(props: EditProps): React.ReactElement {
    const { siteUrl } = props

    const interact = useInteraction()
    const editLink = `${siteUrl}/history#latest`

    useNavShortcut([g, e], <Trans>Go To Edit</Trans>, editLink)

    function handleEditClick(): void {
        interact('Edit Item')
    }

    return (
        <Link href={editLink} onClick={handleEditClick}>
            <Trans>Edit Artist</Trans>
        </Link>
    )
}

export function ArtistActionsMobile(props: Props): React.ReactElement | null {
    const { discogsId, siteUrl } = props

    const [displayArtistActions, setDisplayArtistActions] = React.useState(false)

    function handleCopyClick() {
        void navigator.clipboard.writeText(discogsId.toString())
    }

    return (
        <div className={css.mobileActionsButton}>
            <button
                className={css.ellipsisContainer}
                onClick={() => setDisplayArtistActions(!displayArtistActions)}
                aria-label='mobile contributor actions'
            >
                <EllipsisH aria-hidden='true' />
            </button>
            {displayArtistActions && (
                <div className={css.mobileActionsContainer}>
                    <span>
                        <Edit /> <EditLink siteUrl={siteUrl} />
                    </span>
                    <span>
                        <button onClick={handleCopyClick} className={css.copyButton}>
                            <Vinyl /> {`Copy ID: [${discogsId}]`}
                        </button>
                    </span>
                </div>
            )}
        </div>
    )
}
