import * as React from 'react'

import { Link } from '../../lib/components/link'
import { intersperse } from '../../lib/intersperse'

import css from './style.css'
import { ArtistHeaderFragment } from '../../api/types'
import { ShowMore } from '../../lib/components/show-more'

type Props = {
    groups: NonNullable<ArtistHeaderFragment['groups']>
    setIsHeaderExpanded: (key: boolean) => void
}

const truncateAt = 20

export function Groups(props: Props): React.ReactElement {
    const { groups, setIsHeaderExpanded } = props
    const [shouldShowMore, setShouldShowMore] = React.useState(false)
    const truncate = shouldShowMore ? groups.length : truncateAt

    return (
        <>
            {intersperse(
                ', ',
                groups.slice(0, truncate).map(
                    (group) =>
                        group && (
                            <Link
                                href={group.siteUrl}
                                key={group.discogsId}
                                className={group.isActive ? '' : css.strikeThrough}
                            >
                                <span className={group.isActive ? '' : css.strikeThrough}>{group.name}</span>
                            </Link>
                        ),
                ),
            )}
            <br />
            {groups.length > truncateAt && (
                <ShowMore
                    isShowingMore={shouldShowMore}
                    onToggle={setShouldShowMore}
                    setIsHeaderExpanded={setIsHeaderExpanded}
                />
            )}
        </>
    )
}
