import * as React from 'react'

import { ReleaseSeoFragment, MasterReleaseSeoFragment, ArtistSeoFragment } from '../../api/types'
import { artistGroupSchema, artistIndividualSchema, masterSchema, releaseSchema } from './schema'

export function MasterSEOSchema(props: MasterReleaseSeoFragment): React.ReactElement {
    const __html = JSON.stringify(masterSchema(props))
    return <script type='application/ld+json' dangerouslySetInnerHTML={{ __html }} id='master_schema' />
}

export function ReleaseSEOSchema(props: ReleaseSeoFragment): React.ReactElement {
    const __html = JSON.stringify(releaseSchema(props))
    // TODO: can we put this in the head?
    return <script type='application/ld+json' dangerouslySetInnerHTML={{ __html }} id='release_schema' />
}

export function ArtistSEOSchema(props: ArtistSeoFragment): React.ReactElement {
    const __html = JSON.stringify(props.members.length < 1 ? artistIndividualSchema(props) : artistGroupSchema(props))
    return <script type='application/ld+json' dangerouslySetInnerHTML={{ __html }} id='artist_schema' />
}
