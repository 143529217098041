import { ReleaseSeoFragment, ReleaseLabelRole, MasterReleaseSeoFragment, ArtistSeoFragment } from '../../api/types'
import { year } from '../../lib/date'
import { collateArtists, artistName } from '../../lib/collate-artists'

type Organization = {
    '@type': 'Organization'
    '@id': string
    name?: string
}

type Artist = {
    '@type': 'MusicGroup'
    '@id': string
    name?: string
}

type AggregateRating = {
    '@type': 'AggregateRating'
    ratingValue?: number
    ratingCount?: number
}

type Tracks = {
    '@type': 'MusicRecording'
    title?: string
    duration?: string
}

type MasterOffer = {
    '@type': 'AggregateOffer'
    availability?: string
    priceCurrency?: string
    lowPrice?: number
    offerCount?: number
}

type Breadcrumbs = {
    '@type': 'BreadcrumbList'
    itemListElement: {}[]
}

type MasterReleaseSchema = {
    '@context': 'http://schema.org'
    '@graph': [MasterGraphSchema, Breadcrumbs]
}

type MasterGraphSchema = {
    '@type': 'MusicAlbum'
    '@id': string
    name?: string
    numTracks?: number
    datePublished?: number
    image?: string
    genre?: string[]
    byArtist?: Artist
    aggregateRating?: AggregateRating
    tracks?: Tracks[]
    offers?: MasterOffer
}
type ReleaseSchema = {
    '@context': 'http://schema.org'
    '@type': 'MusicRelease'
    '@id': string
    name: string
    musicReleaseFormat: string
    catalogNumber?: string
    genre?: string[]
    releaseOf: {
        '@type': 'MusicAlbum'
        '@id'?: string
        name: string
        datePublished?: number
        byArtist?: Artist[]
    }
    recordLabel?: Organization[]
    datePublished?: number
    description?: string
    image?: string
    releasedEvent: {
        '@type': 'PublicationEvent'
        startDate?: number
        location?: {
            '@type': 'Country'
            name: string
        }
    }
    offers: {
        '@type': 'AggregateOffer'
        priceCurrency?: string
        lowPrice?: number
        offerCount?: number
        itemOffered: {
            '@type': 'Product'
            name: string
            aggregateRating: {
                '@type': 'AggregateRating'
                ratingCount: number
                ratingValue: number
            }
        }
    }
}

type RLabel = NonNullable<NonNullable<ReleaseSeoFragment['labels']>[number]>
type RArtist = NonNullable<NonNullable<ReleaseSeoFragment['primaryArtists']>[number]>

type ArtistSchema = {
    '@context': 'http://schema.org'
    '@type': 'MusicGroup'
    '@id': string
    name?: string
    description?: string
    image?: string
    member: ArtistMember | ArtistMember[]
    memberOf?: {
        '@type': 'MusicGroup'
        name?: string
        '@id': string
    }[][]
}

type ArtistMember = [
    {
        '@type': 'Person'
        name?: string
        '@id': string
    },
]

function byArtist(artist?: RArtist): Artist | undefined {
    if (!artist?.artist) {
        return undefined
    }

    const id = artistID(artist)
    if (!id) {
        return undefined
    }

    return {
        '@type': 'MusicGroup',
        name: artist.artist.name,
        '@id': id,
    }
}

function artistUrl(artist?: RArtist): string | undefined {
    if (!artist?.artist) {
        return undefined
    }

    const id = artistID(artist)
    if (!id) {
        return undefined
    }

    return id
}
export function masterSchema(masterRelease: MasterReleaseSeoFragment): MasterReleaseSchema {
    return {
        '@context': 'http://schema.org',
        '@graph': [
            {
                '@type': 'MusicAlbum',
                '@id': `https://www.discogs.com${masterRelease.siteUrl}`,
                name: masterRelease.keyRelease.title,
                numTracks: masterRelease.keyRelease.tracks?.length,
                datePublished: masterRelease.keyRelease.released?.length && year(masterRelease.keyRelease.released),
                image: masterRelease.keyRelease.images.edges[0]?.node?.fullsize.sourceUrl,
                genre: masterRelease.keyRelease.genres ?? undefined,
                byArtist: byArtist(masterRelease.keyRelease.primaryArtists?.[0]),
                aggregateRating: {
                    '@type': 'AggregateRating',
                    ratingCount: masterRelease.ratings.totalCount,
                    ratingValue: masterRelease.ratings.averageRating,
                },
                offers: {
                    '@type': 'AggregateOffer',
                    availability: 'http://schema.org/InStock',
                    lowPrice: masterRelease.lowestPrice?.converted.amount,
                    offerCount: masterRelease.listings?.totalCount,
                    priceCurrency: masterRelease.lowestPrice?.converted.currency,
                },
            },
            {
                '@type': 'BreadcrumbList',
                itemListElement: [
                    {
                        '@type': 'ListItem',
                        position: 1,
                        name: masterRelease.keyRelease.primaryArtists?.[0].artist?.name,
                        item: artistUrl(masterRelease.keyRelease.primaryArtists?.[0]),
                    },
                    {
                        '@type': 'ListItem',
                        position: 2,
                        name: masterRelease.keyRelease.title,
                        item: `https://www.discogs.com${masterRelease.siteUrl}`,
                    },
                ],
            },
        ],
    }
}

export function releaseSchema(release: ReleaseSeoFragment): ReleaseSchema {
    return {
        '@context': 'http://schema.org',
        '@type': 'MusicRelease',
        '@id': `https://www.discogs.com${release.siteUrl}`,
        name: release.title,
        musicReleaseFormat: release.formats?.[0]?.name ?? 'Vinyl',
        genre: release.genres ?? undefined,

        description: release.notes?.html.replace(/<br>/g, '\n'),
        datePublished: release.released?.length && year(release.released),
        catalogNumber: release.labels
            ?.map((label: RLabel): string | undefined => label.catalogNumber)
            .filter((x: string | undefined): x is string => Boolean(x))
            .join(', '),
        // Are we just using all labels?
        recordLabel: uniqueOrganizations(
            release.labels
                ?.map(function (label: RLabel): Organization | null {
                    const id = labelID(label)

                    if (!id || label.labelRole !== ReleaseLabelRole.Label) {
                        return null
                    }

                    return {
                        '@type': 'Organization',
                        '@id': id,
                        name: label.label.name,
                    }
                })
                .filter((x: Organization | null): x is Organization => x !== null),
        ),
        releaseOf: {
            '@type': 'MusicAlbum',
            // What if there is no master release?
            '@id': release.masterRelease && `https://www.discogs.com/master/view/${release.masterRelease.discogsId}`,
            name: release.title,
            datePublished: release.released?.length && year(release.released),
            byArtist: release.primaryArtists?.map(byArtist).filter((x: Artist | undefined): x is Artist => Boolean(x)),
        },

        releasedEvent: {
            '@type': 'PublicationEvent',
            startDate: release.released?.length && year(release.released),
            location: release.country ? { '@type': 'Country', name: release.country } : undefined,
        },
        image: release.images.edges[0]?.node?.fullsize.sourceUrl,
        offers: {
            '@type': 'AggregateOffer',
            offerCount: release.marketplaceListings.totalCount,
            priceCurrency: release.statistics.min?.converted.currency,
            lowPrice: release.statistics.min?.converted.amount,
            itemOffered: {
                '@type': 'Product',
                name: `${artists(release.primaryArtists)}${release.title}`,
                aggregateRating: {
                    '@type': 'AggregateRating',
                    ratingCount: release.ratings.totalCount,
                    ratingValue: release.ratings.averageRating,
                },
            },
        },
    }
}

export function artistIndividualSchema(artist: ArtistSeoFragment): ArtistSchema {
    return {
        '@context': 'http://schema.org',
        '@type': 'MusicGroup',
        '@id': `https://www.discogs.com${artist.siteUrl}`,
        name: artist.name,
        image: artist.images.edges[0]?.node?.fullsize.sourceUrl,
        description: artist.profile.html,
        member: [
            {
                '@type': 'Person',
                '@id': `https://www.discogs.com${artist.siteUrl}`,
                name: artist.name,
            },
        ],
        memberOf: artist.groups.map((group) => [
            {
                '@type': 'MusicGroup',
                name: group?.name,
                '@id': group?.siteUrl ? `https://www.discogs.com${group.siteUrl}` : '',
            },
        ]),
    }
}

export function artistGroupSchema(artist: ArtistSeoFragment): ArtistSchema {
    return {
        '@context': 'http://schema.org',
        '@type': 'MusicGroup',
        '@id': `https://www.discogs.com${artist.siteUrl}`,
        name: artist.name,
        image: artist.images.edges[0]?.node?.fullsize.sourceUrl,
        description: artist.profile.html,
        member: artist.members.map(
            (member): ArtistMember => [
                {
                    '@type': 'Person',
                    '@id': member?.artist.siteUrl ? `https://www.discogs.com${member.artist.siteUrl}` : '',
                    name: member?.artist.name,
                },
            ],
        ),
    }
}

export function artists(primaryArtists: RArtist[] | undefined): string {
    if (!primaryArtists || primaryArtists.length === 0) {
        return ''
    }

    return collateArtists(primaryArtists, artistName).join('').concat(' - ')
}

function labelID(label: RLabel): string | undefined {
    if ('discogsId' in label.label) {
        return `https://www.discogs.com/label/${label.label.discogsId}-${label.label.name}`
    }
    return undefined
}

function toID(siteUrl: string): string {
    return `https://www.discogs.com${siteUrl}`
}

function artistID(artist: RArtist): string | undefined {
    if (artist.artist && 'siteUrl' in artist.artist) {
        return toID(artist.artist.siteUrl)
    }
    return undefined
}

function uniqueOrganizations(organizations: Organization[] | undefined): Organization[] | undefined {
    if (!organizations) {
        return undefined
    }

    const byId: { [k: string]: Organization } = {}
    for (const org of organizations) {
        byId[org['@id']] = org
    }
    return Object.values(byId)
}
