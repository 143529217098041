import * as React from 'react'
import { Trans } from '@lingui/macro'
import css from './styles.css'
import { Link } from '../../lib/components/link'
import { ShowMore } from '../../lib/components/show-more'
import { AltArtistFragment, ArtistHeaderFragment } from '../../api/types'

type Props = {
    profile: NonNullable<ArtistHeaderFragment['profile']>
    setIsHeaderExpanded: (key: boolean) => void
    altArtist: AltArtistFragment['altArtist']
}

export function Profile(props: Props): React.ReactElement {
    const { profile, setIsHeaderExpanded, altArtist } = props
    const [shouldShowMore, setShouldShowMore] = React.useState(false)

    function hasLineBreaks(): boolean {
        const html = profile.html.trim()
        return html.includes('<br>')
    }

    return (
        <>
            <div className={css.profileContainer}>
                {altArtist ? (
                    <Trans>
                        <b>DO NOT USE</b> Please use <Link href={altArtist.siteUrl}>{altArtist.name}</Link> instead.
                    </Trans>
                ) : (
                    <p
                        className={`${css.profileText} ${shouldShowMore ? css.hideContent : ''}`}
                        dangerouslySetInnerHTML={{ __html: profile.html }}
                    />
                )}
            </div>
            {(profile.html.length > 400 || hasLineBreaks()) && (
                <ShowMore
                    isShowingMore={shouldShowMore}
                    onToggle={setShouldShowMore}
                    setIsHeaderExpanded={setIsHeaderExpanded}
                />
            )}
        </>
    )
}
