import * as React from 'react'

import { ArtistVideosFragment } from '../../api/types'
import { Videos } from '../videos'

type Props = ArtistVideosFragment

export function ArtistVideos(props: Props): React.ReactElement {
    return <Videos {...props} editUrl={`/artist/${props.discogsId}-/videos/update`} />
}
