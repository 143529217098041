import * as React from 'react'
import css from './styles.css'
import { Facets, getFacets } from '../artist-discography/helpers'
import { CaretDown, AngleDown, Check, CaretRight, AngleRight } from '../../lib/components/icon'
import { useInteraction } from '../../lib/components/analytics'
import { TotalsDataObj, UIProps, PersistedReleaseTypeTotals } from '../artist-discography'
import classnames from 'classnames'
import { Trans } from '@lingui/macro'
import { ArtistCreditFacets } from '../../api/types'
import { Loading } from '../../lib/components/loading'
import { useHistory, useLocation } from 'react-router'
import { set } from '../../lib/query'

type Props = {
    dispatch: UIProps['dispatch']
    creditCategoryTotals: ArtistCreditFacets['facets'] | ''
    superFacetTotals: TotalsDataObj['superFacetTotals'] | null
    persistedReleaseTypeTotals: PersistedReleaseTypeTotals
    filterSelected: string[]
    isSidebarOpen: boolean
    toggleSidebar: () => void
    itemsOnPage: number | undefined
    creditName: string
    creditSub: boolean
    openSuperFacetState: { [key: string]: boolean }
}

type SuperFacets = {
    facetName: string
    subFacetDetails: []
    totalCount: number
}[]

export function ArtistSidebar(props: Props): React.ReactElement | null {
    const {
        dispatch,
        creditCategoryTotals,
        superFacetTotals,
        persistedReleaseTypeTotals,
        filterSelected,
        isSidebarOpen,
        toggleSidebar,
        itemsOnPage,
        creditName,
        creditSub,
        openSuperFacetState,
    } = props

    const track = useInteraction()
    const history = useHistory()
    const location = useLocation()

    if (!creditCategoryTotals) {
        return null
    }

    const { credits } = getFacets(creditCategoryTotals as Facets)

    const acceptedKeys = ['All Releases', 'Releases', 'Appearances', 'Unofficial', 'Credits']

    const superFacets = Object.values(superFacetTotals ?? {})
        .flatMap((value) => value)
        .filter((val) => val.totalCount > 0) as SuperFacets

    function dispatchCreditState(creditSub: boolean, updatedOpenSuperFacetState: { [key: string]: boolean }) {
        dispatch({
            type: 'creditState',
            creditSub,
            openSuperFacetState: updatedOpenSuperFacetState,
        })
    }

    function clickHandler(subFacets: string[], nestedSubFacet: string = '') {
        const updatedOpenSuperFacetState = { ...openSuperFacetState }

        // allow users to open/close superFacets
        // check if the key value pair exists in the object already
        if (subFacets.length === 1 && subFacets[0] in updatedOpenSuperFacetState) {
            // if it does check if it is true
            if (updatedOpenSuperFacetState[subFacets[0]]) {
                // next check the filterSelected value to ensure its not a Credit facet
                if (acceptedKeys.includes(filterSelected[0]) || filterSelected[0] === subFacets[0]) {
                    // if filterSelected is one of the accepted keys
                    // this means the user is clicking it again to close it
                    // check to see if we need to fire a dispatch to fetch the superFacet data
                    // we do this by checking to see if the filterSelected array contains a
                    // releaseType. If it does we know that the last button they clicked was a releaseType
                    // and they are wanting to return to the overarching superFacet.

                    if (subFacets.length === filterSelected.length && !nestedSubFacet) {
                        updatedOpenSuperFacetState[subFacets[0]] = !updatedOpenSuperFacetState[subFacets[0]]
                        dispatchCreditState(creditSub, updatedOpenSuperFacetState)
                        return
                    }
                    history.push(
                        set(location, {
                            superFilter: subFacets[0],
                            subFilter: null,
                            creditFilter: nestedSubFacet ? nestedSubFacet : null,
                            page: null,
                        }),
                    )
                    // if there is no nestedSubFacet passed to this function this means the user
                    // is not clicking on a facet nested beneath a creditFacet
                } else if (!nestedSubFacet) {
                    // The user has clicked to open a credit facet and is now
                    // attempting to close another credit facet
                    updatedOpenSuperFacetState[subFacets[0]] = !updatedOpenSuperFacetState[subFacets[0]]
                    dispatchCreditState(creditSub, updatedOpenSuperFacetState)
                    return
                }
            } else if (filterSelected[0] === subFacets[0]) {
                // if we are here this means that the value does exist in the object
                // but is false. This means the facet is closed
                // we now need to check if the user is simply clicking to open/close
                // while the correct data is on the page
                // or if we need to fire another query
                if (!creditName) {
                    updatedOpenSuperFacetState[subFacets[0]] = !updatedOpenSuperFacetState[subFacets[0]]
                    dispatchCreditState(creditSub, updatedOpenSuperFacetState)
                    return
                }
                updatedOpenSuperFacetState[subFacets[0]] = !updatedOpenSuperFacetState[subFacets[0]]
                dispatchCreditState(creditSub, updatedOpenSuperFacetState)
            } else {
                // close credits if the user has selected a superFacet.
                // If credits is open it will populate with releaseTypes
                if (acceptedKeys.includes(subFacets[0])) {
                    updatedOpenSuperFacetState.Credits = false
                }
                if (subFacets[0] === 'Credits') {
                    // close all other superFacets otherwise when the user selects one
                    // after opening credits, all previously opened superFacets will be re-opened.
                    for (const key in updatedOpenSuperFacetState) {
                        // only close the following superFacets
                        if (['Releases', 'Appearances', 'Unofficial'].includes(key)) {
                            updatedOpenSuperFacetState[key] = false
                        }
                    }
                }
                history.push(
                    set(location, { superFilter: subFacets[0], subFilter: null, creditFilter: null, page: null }),
                )
                updatedOpenSuperFacetState[subFacets[0]] = !updatedOpenSuperFacetState[subFacets[0]]
                dispatchCreditState(creditSub, updatedOpenSuperFacetState)
                // fire the dispatch
            }
        } else {
            // if its not add it to the object with a value of true
            // check to see if the user is clicking on a creditFacet if they are we dont want to
            // change credits to false.
            // keep superFacets open but close the credits superFacet
            // if it remains open it will display releaseType counts
            if (acceptedKeys.includes(subFacets[0])) {
                if (subFacets[0] === 'Credits') {
                    // Close all other superFacets - this is needed to if the user instantly clicks on credits when the page first loads
                    // if we dont do this the initially opened superFacet will remain open.
                    for (const key in updatedOpenSuperFacetState) {
                        if (key !== 'Credits') {
                            updatedOpenSuperFacetState[key] = false
                        }
                    }
                } else {
                    updatedOpenSuperFacetState.Credits = false
                }
            }
            history.push(
                set(location, {
                    superFilter: subFacets[0],
                    subFilter: subFacets[1] ? subFacets[1] : null,
                    creditFilter: null,
                    page: null,
                }),
            )
            updatedOpenSuperFacetState[subFacets[0]] = true
            dispatchCreditState(creditSub, updatedOpenSuperFacetState)
            // fire a dispatch
        }

        if (!acceptedKeys.includes(subFacets[0]) || subFacets[1]) {
            if (isSidebarOpen) {
                toggleSidebar()
            }
        }

        track(`Filter - ${subFacets.join(' - ')}`)

        dispatch({ type: 'creditName', creditName: nestedSubFacet })
        dispatch({ type: 'sidebarFilters', filterSelected: subFacets, initialFilter: [''] })

        if (nestedSubFacet) {
            dispatchCreditState(true, updatedOpenSuperFacetState)
        } else {
            dispatchCreditState(false, updatedOpenSuperFacetState)
        }
    }

    function mobileCreditFacetExpanderClickHandler(e: { stopPropagation: () => void }, creditFacet: string) {
        e.stopPropagation()
        const updatedOpenSuperFacetState = { ...openSuperFacetState }
        updatedOpenSuperFacetState[creditFacet] = !updatedOpenSuperFacetState[creditFacet]
        dispatchCreditState(creditSub, updatedOpenSuperFacetState)
    }

    return (
        <div className={classnames(css.sidebar)}>
            {isSidebarOpen && (
                <div className={css.closeSidebar}>
                    <button onClick={toggleSidebar}>Close</button>
                    <h2>Select a category</h2>
                </div>
            )}
            {superFacets.map(function (superFacet) {
                const superFacetIsExpanded = openSuperFacetState[superFacet.facetName]
                return (
                    <div key={superFacet.facetName} className={css.facetsContainer}>
                        <button
                            className={classnames(
                                css.superFacets,
                                superFacetIsExpanded &&
                                    !filterSelected[1] &&
                                    filterSelected[0] === superFacet.facetName &&
                                    css.expanded,
                            )}
                            onClick={() => clickHandler([superFacet.facetName])}
                            aria-expanded={
                                superFacetIsExpanded && !filterSelected[1] && filterSelected[0] === superFacet.facetName
                            }
                            key={superFacet.facetName}
                        >
                            {isSidebarOpen &&
                                (superFacetIsExpanded ? (
                                    <AngleDown aria-hidden='true' />
                                ) : (
                                    <AngleRight aria-hidden='true' />
                                ))}
                            {!isSidebarOpen &&
                                (superFacetIsExpanded ? (
                                    <CaretDown aria-hidden='true' />
                                ) : (
                                    <CaretRight aria-hidden='true' />
                                ))}
                            <p className={css.superFacet}>
                                <Trans>{superFacet.facetName}</Trans>
                            </p>
                            <p
                                className={classnames(
                                    css.superFacetCount,
                                    !filterSelected[1] && isSidebarOpen && superFacetIsExpanded && css.mobileFocusUI,
                                    superFacetIsExpanded &&
                                        !filterSelected[1] &&
                                        filterSelected[0] === superFacet.facetName &&
                                        css.superFacetCountExpanded,
                                )}
                            >
                                <Trans>{superFacet.totalCount}</Trans>
                            </p>
                            {!filterSelected[1] &&
                                isSidebarOpen &&
                                filterSelected[0] === superFacet.facetName &&
                                superFacetIsExpanded && (
                                    <div className={css.mobileSelectedTick}>
                                        <Check />
                                    </div>
                                )}
                        </button>
                        {isSidebarOpen &&
                            ['Releases', 'Appearances', 'Unofficial'].includes(filterSelected[0]) &&
                            /* eslint-disable @typescript-eslint/no-unnecessary-condition */
                            !persistedReleaseTypeTotals[
                                filterSelected[0] as keyof typeof persistedReleaseTypeTotals
                            ][0] &&
                            !itemsOnPage &&
                            superFacetIsExpanded && (
                                <div className={css.mobileUiLoadingState}>
                                    <Loading />
                                </div>
                            )}
                        {superFacetIsExpanded &&
                            ['Releases', 'Appearances', 'Unofficial'].includes(filterSelected[0]) &&
                            ['Albums', 'Singles & EPs', 'Compilations', 'Mixes', 'Videos', 'DJ Mixes', 'Miscellaneous']
                                .flatMap(function (facetName) {
                                    const matchingItem = persistedReleaseTypeTotals[
                                        superFacet.facetName as keyof typeof persistedReleaseTypeTotals
                                    ].find((item) => item.facetName === facetName)
                                    return matchingItem ? [{ ...matchingItem }] : []
                                })
                                .map(function (facet: { facetName: string; totalCount: number }) {
                                    const releaseTypeIsSelected =
                                        filterSelected.includes(facet.facetName) &&
                                        filterSelected.includes(superFacet.facetName)
                                    return (
                                        <button
                                            className={classnames(
                                                css.facets,
                                                releaseTypeIsSelected && css.facetSelected,
                                            )}
                                            onClick={() => clickHandler([superFacet.facetName, facet.facetName])}
                                            key={`${facet.facetName} - ${superFacet.facetName}`}
                                            disabled={releaseTypeIsSelected}
                                        >
                                            <p className={css.facet}>
                                                <Trans>{facet.facetName}</Trans>
                                            </p>
                                            <span
                                                className={classnames(
                                                    css.subFacetTotalCounts,
                                                    releaseTypeIsSelected && css.subFacetTotalCountsSelected,
                                                )}
                                            >
                                                <p>{facet.totalCount}</p>
                                            </span>
                                            {isSidebarOpen &&
                                                superFacetIsExpanded &&
                                                releaseTypeIsSelected &&
                                                filterSelected[1] &&
                                                filterSelected[1] === facet.facetName && (
                                                    <div className={css.mobileSelectedTick}>
                                                        <Check />
                                                    </div>
                                                )}
                                        </button>
                                    )
                                })}
                    </div>
                )
            })}
            <div>
                {openSuperFacetState.Credits && (
                    <div>
                        {Object.entries(credits).map(function ([key, value]) {
                            const subFacetIsExpanded = openSuperFacetState[key]
                            return (
                                <div key={key}>
                                    {value.subFacet.length > 0 ? (
                                        <div className={css.creditsFacetsContainer}>
                                            <button
                                                className={classnames(
                                                    css.facets,
                                                    css.creditFacets,
                                                    filterSelected[0] === key && !creditSub && css.facetSelected,
                                                )}
                                                onClick={() => clickHandler([key])}
                                                aria-expanded={filterSelected[0] === key && !creditSub}
                                            >
                                                {isSidebarOpen ? (
                                                    subFacetIsExpanded ? (
                                                        <AngleDown aria-hidden='true' />
                                                    ) : (
                                                        <button
                                                            className={css.mobileCreditFacetExpander}
                                                            onClick={(e) =>
                                                                mobileCreditFacetExpanderClickHandler(e, key)
                                                            }
                                                        >
                                                            <AngleRight aria-hidden='true' />
                                                        </button>
                                                    )
                                                ) : subFacetIsExpanded ? (
                                                    <CaretDown aria-hidden='true' className={css.iconDown} />
                                                ) : (
                                                    <CaretRight aria-hidden='true' />
                                                )}
                                                <p className={css.facet}>
                                                    <Trans>{key}</Trans>
                                                </p>
                                                <span className={css.subFacetTotalCounts}>
                                                    <p>{value.totalCount}</p>
                                                </span>
                                            </button>
                                            {subFacetIsExpanded &&
                                                value.subFacet.map(
                                                    (subFacet: { totalCount: number; subFacetName: string }) => (
                                                        <button
                                                            onClick={() => clickHandler([key], subFacet.subFacetName)}
                                                            key={subFacet.subFacetName}
                                                            className={classnames(
                                                                css.facets,
                                                                css.creditFacets,
                                                                creditName === subFacet.subFacetName &&
                                                                    creditSub &&
                                                                    css.facetSelected,
                                                            )}
                                                            disabled={creditName === subFacet.subFacetName}
                                                        >
                                                            <p className={css.subFacets}>
                                                                <Trans>{subFacet.subFacetName}</Trans>
                                                            </p>
                                                            <span className={css.subFacetTotalCounts}>
                                                                <p>{subFacet.totalCount}</p>
                                                            </span>
                                                        </button>
                                                    ),
                                                )}
                                        </div>
                                    ) : (
                                        !acceptedKeys.includes(key) && (
                                            <button
                                                className={classnames(
                                                    css.facets,
                                                    filterSelected.includes(key) && css.facetSelected,
                                                )}
                                                onClick={() => clickHandler([key])}
                                                disabled={filterSelected.includes(key)}
                                            >
                                                <p className={css.facet}>
                                                    <Trans>{key}</Trans>
                                                </p>
                                                <span className={css.subFacetTotalCounts}>
                                                    <p>
                                                        <Trans>{value.totalCount}</Trans>
                                                    </p>
                                                </span>
                                            </button>
                                        )
                                    )}
                                </div>
                            )
                        })}
                    </div>
                )}
            </div>
        </div>
    )
}
