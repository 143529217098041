import * as React from 'react'
import { Trans, t } from '@lingui/macro'

import { ArtistHeaderFragment } from '../../api/types'
import { Link } from '../../lib/components/link'
import { ReleaseThumbnail as ArtistsThumbnail } from '../release-thumbnail'
import { GalleryLink } from '../image-gallery/link'
import { useLocalisation } from '../../lib/i18n'
import { useLogin } from '../../lib/login'

import css from './styles.css'
import { NSFWImagePlaceholderSmall } from '../../lib/components/nsfw-image-placeholder'

type Props = {
    discogsId: number
    images: NonNullable<ArtistHeaderFragment['images']>
    name: string
}

export function ArtistImages(props: Props): React.ReactElement {
    const { loggedIn } = useLogin()
    const { discogsId, images, name } = props
    const { i18n } = useLocalisation()

    const image = images.edges[0]?.node

    const alt = t(i18n)`${name}`

    if (!image) {
        const add = `/artist/${discogsId}/images/update`

        return (
            <div>
                <Link href={add}>
                    <ArtistsThumbnail alt={alt} discogsId={discogsId} />
                </Link>
                <p className={css.add}>
                    <Link href={add}>
                        <Trans>Add An Image</Trans>
                    </Link>
                </p>
            </div>
        )
    }

    if (image.nsfw && !loggedIn) {
        return (
            <div>
                <GalleryLink imageId={image.id} className={css.thumbnail}>
                    <NSFWImagePlaceholderSmall showButton />
                    {images.totalCount > 1 && (
                        <p className={css.moreImages}>
                            <Trans>More images</Trans>
                        </p>
                    )}
                </GalleryLink>
            </div>
        )
    }

    return (
        <div className={css.more}>
            <GalleryLink imageId={image.id} className={css.thumbnail}>
                <ArtistsThumbnail image={image.thumbnail} alt={alt} preload lazy={false} discogsId={discogsId} />
            </GalleryLink>
            <p>
                {images.totalCount > 1 && (
                    <GalleryLink imageId={image.id} className={css.thumbnail}>
                        <Trans>More images</Trans>
                    </GalleryLink>
                )}
            </p>
        </div>
    )
}
