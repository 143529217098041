import * as React from 'react'

import { Link } from '../../lib/components/link'
import { intersperse } from '../../lib/intersperse'

import css from './style.css'
import { ArtistHeaderFragment } from '../../api/types'
import { ShowMore } from '../../lib/components/show-more'

type Props = {
    members: NonNullable<ArtistHeaderFragment['members']>
    setIsHeaderExpanded: (key: boolean) => void
}

const truncateAt = 20

export function Members(props: Props): React.ReactElement {
    const { members, setIsHeaderExpanded } = props
    const [shouldShowMore, setShouldShowMore] = React.useState(false)
    const truncate = shouldShowMore ? members.length : truncateAt
    return (
        <>
            {intersperse(
                ', ',
                members.slice(0, truncate).map(
                    (member) =>
                        member && (
                            <Link href={member.artist.siteUrl} key={member.artist.discogsId}>
                                <span className={member.isActive ? '' : css.strikeThrough}>{member.artist.name}</span>
                            </Link>
                        ),
                ),
            )}
            <br />
            {members.length > truncateAt && (
                <ShowMore
                    isShowingMore={shouldShowMore}
                    onToggle={setShouldShowMore}
                    setIsHeaderExpanded={setIsHeaderExpanded}
                />
            )}
        </>
    )
}
