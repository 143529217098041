import * as React from 'react'
import { Trans } from '@lingui/macro'
import { useHistory } from 'react-router-dom'

import { addState, Dispatcher, WithProps } from '../../lib/add-state'

import { ArtistViews } from '../artist-views'
import { ReleaseCard } from '../release-card'
import { ArtistSidebar } from '../artist-sidebar'
import { ArtistFiltersUI, Count, Pagination, SortProps } from '../artist-filters'
import { Loading } from '../../lib/components/loading'
import classNames from 'classnames'
import {
    useArtistDiscographyDataQuery,
    OrderByDirection,
    ArtistHeaderType,
    ArtistReleaseGroupHeaderInput,
    ArtistCreditFacets,
    ArtistDiscographyDataQuery,
    AltArtistFragment,
    ArtistPaginationInfo,
    useArtistDiscographyFacetsQuery,
    Maybe,
} from '../../api/types'

import {
    Facets,
    getCounts,
    getFacets,
    getReleaseTypeTotals,
    ReleaseTypeTotalsReturnType,
    getSuperFacetTotals,
    SuperFacetTotalsReturnType,
} from './helpers'

import css from './styles.css'
import { useBlockScroll } from '../../lib/use-block-scroll'
import { ErrorSection } from '../../lib/components/error-section'
import { AdDiscography, AdDiscographyB } from '../../lib/components/ads'
import { useQueryParam, useQueryParams } from '../../lib/query'
import { usePersist } from '../../lib/use-persist'
import { FacetObjects } from '../../lib/components/facetFilters'
import { safeGetUrlParams } from '../../lib/components/facetFilters/utils'
import { safeGetItem, safeRemoveItem, safeSetItem } from '../../lib/components/pagination/paginationUtils'
import { ApolloError } from '@apollo/client'

export type TotalsDataObj = {
    credits: number
    releaseTypeTotals: ReleaseTypeTotalsReturnType
    superFacetTotals: SuperFacetTotalsReturnType
}

type Props = {
    discogsId: number
    altArtist: AltArtistFragment['altArtist']
    name: string
    tab: string
    setTab: (value: string) => void
    headerIsExpanded: boolean
    setArtistHasNoReleases: (value: boolean) => void
    url: string
}

export type PersistedReleaseTypeTotals = {
    Releases: { totalCount: number; facetName: string; subFacetDetails?: [] }[]
    Appearances: { totalCount: number; facetName: string; subFacetDetails?: [] }[]
    Unofficial: { totalCount: number; facetName: string; subFacetDetails?: [] }[]
}

export type UseHistoryStateType = {
    name: string
    url: string
}

export type State = {
    perPage: number
    page: number
    filterSelected: string[]
    initialFilter: string[]
    order: OrderByDirection
    view: 'textWithCovers' | 'coversOnly' | 'textOnly'
    queryHeaders: ArtistReleaseGroupHeaderInput[]
    artistPages: ArtistPaginationInfo['artistPages']
    releaseTypes: string[]
    releaseTypeTotals: TotalsDataObj['releaseTypeTotals'] | null
    creditName: string
    formats: string[]
    labels: string[]
    countries: string[]
    years: number[]
    formatFacets: FacetObjects | null
    labelFacets: FacetObjects | null
    countryFacets: FacetObjects | null
    yearFacets: FacetObjects | null
    creditSub: boolean
    openSuperFacetState: { [key: string]: boolean }
    anvs: string[]
    search: string
    creditCategoryTotals: ArtistCreditFacets['facets']
    superFacetTotals: TotalsDataObj['superFacetTotals'] | null
    persistedReleaseTypeTotals: PersistedReleaseTypeTotals
    searchResultsTotals: SuperFacetTotalsReturnType | null
    advancedFilterSelected: { [key: string]: boolean }
    advancedFilterShowState: {
        desktop: boolean | null
        mobile: boolean
    }
    loading: boolean
    data: ArtistDiscographyDataQuery | null
    error: ApolloError | undefined
}

export type UIProps = WithProps<Props, State, Action>

export type Action =
    | {
          type: 'advancedFilterSelected'
          advancedFilterSelected: { [key: string]: boolean }
          advancedFilterShowState: {
              desktop: boolean | null
              mobile: boolean
          }
      }
    | { type: 'advancedFiltersApplied'; formats: string[]; labels: string[]; countries: string[]; years: number[] }
    | { type: 'sidebarFilters'; filterSelected: string[]; initialFilter: string[] }
    | { type: 'sortBy'; order: OrderByDirection }
    | { type: 'view'; view: 'textWithCovers' | 'coversOnly' | 'textOnly' }
    | { type: 'pagination'; page: number; perPage: number; artistPages: ArtistPaginationInfo['artistPages'] }
    | { type: 'creditName'; creditName: string }
    | { type: 'anv'; anvs: string[] }
    | { type: 'search'; search: string }
    | { type: 'data'; data: ArtistDiscographyDataQuery }
    | {
          type: 'creditState'
          creditSub: boolean
          openSuperFacetState: { [key: string]: boolean }
      }
    | {
          type: 'error'
          error: ApolloError | undefined
      }

export function reducer(props: Props, state: State, action: Action): State {
    switch (action.type) {
        case 'data': {
            if (action.data.artist?.releases) {
                const sidebarFacetData = {
                    releaseTypeTotals: getReleaseTypeTotals(
                        action.data.artist.facets.artistCreditFacets.facets as Facets,
                    ),
                    superFacetTotals: getSuperFacetTotals(
                        action.data.artist.facets.artistCreditFacets.facets as Facets,
                    ),
                }
                const searchResultCount = getSuperFacetTotals(
                    action.data.artist.releases.artistCreditFacets.facets as Facets,
                )

                const advancedFilterFacetData = getFacets(
                    action.data.artist.releases.artistCreditFacets.facets as Facets,
                )

                return {
                    ...state,
                    loading: false,
                    data: action.data,
                    initialFilter:
                        sidebarFacetData.superFacetTotals.Releases.length === 0
                            ? sidebarFacetData.superFacetTotals.Appearances.length === 0
                                ? sidebarFacetData.superFacetTotals.Unofficial.length === 0
                                    ? ['Credits']
                                    : ['Unofficial']
                                : ['Appearances']
                            : ['Releases'],
                    formatFacets: advancedFilterFacetData.format,
                    labelFacets: advancedFilterFacetData.label,
                    countryFacets: advancedFilterFacetData.country,
                    yearFacets: advancedFilterFacetData.year,
                    creditCategoryTotals: action.data.artist.facets.artistCreditFacets.facets,
                    superFacetTotals: sidebarFacetData.superFacetTotals,
                    releaseTypeTotals: sidebarFacetData.releaseTypeTotals,
                    searchResultsTotals: searchResultCount,
                    persistedReleaseTypeTotals:
                        state.labels.length === 0 &&
                        state.formats.length === 0 &&
                        state.countries.length === 0 &&
                        state.years.length === 0
                            ? {
                                  ...state.persistedReleaseTypeTotals,
                                  [state.filterSelected[0] ? state.filterSelected[0] : state.initialFilter[0]]:
                                      sidebarFacetData.releaseTypeTotals,
                              }
                            : { ...state.persistedReleaseTypeTotals },
                }
            }
            return {
                ...state,
                data: action.data,
            }
        }
        case 'advancedFilterSelected':
            return {
                ...state,
                advancedFilterSelected: action.advancedFilterSelected,
                advancedFilterShowState: action.advancedFilterShowState,
            }
        case 'pagination': {
            return {
                ...state,
                page: action.page,
                perPage: action.perPage,
                artistPages: action.artistPages,
            }
        }
        case 'creditState': {
            return {
                ...state,
                creditSub: action.creditSub,
                openSuperFacetState: action.openSuperFacetState,
            }
        }
        case 'sidebarFilters': {
            const acceptedKeys = ['All Releases', 'Releases', 'Appearances', 'Unofficial', 'Credits']
            const updatedFilterSelections = { ...state.advancedFilterSelected }
            Object.keys(updatedFilterSelections).forEach(function (val) {
                updatedFilterSelections[val] = false
            })
            return {
                ...state,
                filterSelected: action.filterSelected,
                initialFilter: action.initialFilter,
                advancedFilterSelected: updatedFilterSelections,
                labels: [],
                formats: [],
                countries: [],
                years: [],
                loading: true,
                page: 1,
                releaseTypes: action.filterSelected[1] ? [action.filterSelected[1]] : state.releaseTypes,
                queryHeaders:
                    // if the user has selected a releaseType
                    action.filterSelected.length > 1
                        ? action.filterSelected
                              .filter((value) => !acceptedKeys.includes(value))
                              .map((filter: string) => ({
                                  headerName: filter,
                                  headerType: ArtistHeaderType.Releasetype,
                              }))
                        : acceptedKeys.includes(action.filterSelected[0])
                        ? [
                              { headerName: 'Albums', headerType: ArtistHeaderType.Releasetype },
                              { headerName: 'Singles & EPs', headerType: ArtistHeaderType.Releasetype },
                              { headerName: 'Compilations', headerType: ArtistHeaderType.Releasetype },
                              { headerName: 'Videos', headerType: ArtistHeaderType.Releasetype },
                              { headerName: 'Miscellaneous', headerType: ArtistHeaderType.Releasetype },
                              { headerName: 'Mixes', headerType: ArtistHeaderType.Releasetype },
                              { headerName: 'DJ Mixes', headerType: ArtistHeaderType.Releasetype },
                          ]
                        : [{ headerName: action.filterSelected[0], headerType: ArtistHeaderType.Credit }],
            }
        }
        case 'sortBy':
            return {
                ...state,
                loading: true,
                order: action.order,
                page: 1,
            }
        case 'view':
            return {
                ...state,
                view: action.view,
            }
        case 'creditName': {
            return {
                ...state,
                creditName: action.creditName,
                queryHeaders: [{ headerName: action.creditName, headerType: ArtistHeaderType.Credit }],
                releaseTypes: [],
                loading: true,
                page: 1,
            }
        }
        case 'advancedFiltersApplied': {
            return {
                ...state,
                formats: action.formats,
                labels: action.labels,
                countries: action.countries,
                years: action.years,
                loading: true,
                page: 1,
            }
        }
        case 'anv': {
            return {
                ...state,
                anvs: action.anvs,
                loading: true,
                page: 1,
            }
        }
        case 'search': {
            return {
                ...state,
                search: action.search,
                page: 1,
            }
        }
        case 'error': {
            return {
                ...state,
                error: action.error,
            }
        }
        default:
            return state
    }
}

function useInitial(): State {
    const [anv] = useQueryParams('anv')
    const [searchParam] = useQueryParams('searchParam')
    const [pageNumber] = useQueryParam('page')
    const [sortOrder] = usePersist<SortProps['order']>('artist_page_sort_order')
    const [showNumber] = usePersist<number>('artist_page_show_number')
    const artistPagesInitialData = safeGetItem('artistPagesData')
    const [viewType] = usePersist<State['view']>('artist_page_view')
    const urlParams = safeGetUrlParams()

    function useSessionArtistData(jsonString: string | null | undefined, pageNumber: number): [] {
        const vfFiltersInUrl =
            [...urlParams.formatParam, ...urlParams.labelParam, ...urlParams.countryParams, ...urlParams.yearParams]
                .length > 0

        if (!jsonString) {
            return []
        }
        if (pageNumber === 0 || vfFiltersInUrl) {
            safeRemoveItem('artistPagesData')
            return []
        }
        try {
            const parsedData = JSON.parse(jsonString)
            return parsedData
        } catch (error) {
            return []
        }
    }

    return {
        loading: true,
        data: null,
        formatFacets: null,
        labelFacets: null,
        countryFacets: null,
        yearFacets: null,
        persistedReleaseTypeTotals: {
            Releases: [],
            Appearances: [],
            Unofficial: [],
        },
        superFacetTotals: null,
        perPage: typeof showNumber === 'number' ? showNumber : 25,
        page: Number(pageNumber) === 0 ? 1 : Number(pageNumber),
        creditSub: false,
        openSuperFacetState: {},
        filterSelected: [''],
        initialFilter: ['Releases'],
        order: sortOrder && 'ASC' !== sortOrder && ['ASC', 'DESC'].includes(sortOrder) ? sortOrder : 'ASC',
        view: viewType ? viewType : 'textWithCovers',
        queryHeaders: [
            { headerName: 'Albums', headerType: ArtistHeaderType.Releasetype },
            { headerName: 'Singles & EPs', headerType: ArtistHeaderType.Releasetype },
            { headerName: 'Compilations', headerType: ArtistHeaderType.Releasetype },
            { headerName: 'Videos', headerType: ArtistHeaderType.Releasetype },
            { headerName: 'Miscellaneous', headerType: ArtistHeaderType.Releasetype },
            { headerName: 'Mixes', headerType: ArtistHeaderType.Releasetype },
            { headerName: 'DJ Mixes', headerType: ArtistHeaderType.Releasetype },
        ],
        artistPages: useSessionArtistData(artistPagesInitialData, Number(pageNumber)),
        releaseTypes: ['Albums', 'Singles & EPs', 'Compilations', 'Videos', 'Miscellaneous', 'Mixes', 'DJ Mixes'],
        creditName: '',
        formats: urlParams.formatParam,
        labels: urlParams.labelParam,
        countries: urlParams.countryParams,
        years: urlParams.yearParams,
        anvs: anv ? [anv as string] : [],
        search: (searchParam as string | null) ?? '',
        creditCategoryTotals: undefined,
        advancedFilterSelected: urlParams.allParamsFromUrl,
        releaseTypeTotals: null,
        searchResultsTotals: null,
        advancedFilterShowState: { desktop: null, mobile: false },
        error: undefined,
    }
}

function useEffect(props: Props, state: State, dispatch: Dispatcher<Action>): void {
    const { discogsId, name, altArtist, url } = props
    const {
        perPage,
        order,
        queryHeaders,
        page,
        artistPages,
        creditName,
        formats,
        labels,
        countries,
        years,
        anvs,
        search,
        releaseTypes,
        filterSelected,
        initialFilter,
        creditCategoryTotals,
        advancedFilterShowState,
        advancedFilterSelected,
        view,
    } = state
    const { setArtistHasNoReleases } = props

    const yearsSelected = years.map(Number)

    const creditCategoryData = useArtistDiscographyFacetsQuery({
        ssr: true,
        fetchPolicy: process.browser && !process.isDevWatching ? 'cache-only' : 'cache-first',
        returnPartialData: true,
        errorPolicy: 'all',
        variables: {
            discogsId,
            anvs,
            creditCategory: filterSelected[0] ? filterSelected[0] : initialFilter[0],
        },
        skip: !discogsId,
    })

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const facetData = creditCategoryData.data?.artist?.facets?.artistCreditFacets?.facets?.[1]

    function getCreditCategory(
        facetData: Maybe<{
            superFacetName: string
            facetDetails?: Maybe<Maybe<{ facetName: string; totalCount: number }>[]>
        }>,
    ) {
        // this function prevents us rendering credit categories with 0 releases
        const possibleCreditCategories = ['Releases', 'Appearances', 'Unofficial', 'Credits']

        for (const category of possibleCreditCategories) {
            const facet = facetData?.facetDetails?.find((facet) => facet?.facetName === category)
            if (facet && facet.totalCount > 0) {
                return category
            }
        }

        return 'Releases'
    }

    const creditCategory = filterSelected[0] ? filterSelected[0] : getCreditCategory(facetData)

    function queryHeaderHandler() {
        if (!['Releases', 'Appearances', 'Unofficial'].includes(creditCategory)) {
            return [{ headerName: creditCategory, headerType: ArtistHeaderType.Credit }]
        }
        return queryHeaders
    }

    const [pageNumber] = useQueryParam('page')

    const artistPagesData = artistPages ? (artistPages.length > 0 ? artistPages : []) : []
    const pageNumberFromUrl = pageNumber ? Number(pageNumber) : page

    const res = useArtistDiscographyDataQuery({
        ssr: true,
        returnPartialData: true,
        errorPolicy: 'all',
        variables: {
            discogsId,
            perPage,
            sortDirection: order,
            headers: queryHeaderHandler(),
            creditCategory,
            desiredPage: pageNumberFromUrl,
            artistPages: pageNumber ? artistPagesData : [],
            creditName,
            formats,
            labels,
            years: yearsSelected,
            countries,
            anvs,
            search,
            releaseTypes,
        },
        skip: !discogsId || creditCategoryData.loading,
    })

    React.useEffect(
        function () {
            if (res.data) {
                dispatch({
                    type: 'data',
                    data: res.data,
                })
            }
        },
        [res],
    )

    React.useEffect(
        function () {
            if (res.error || creditCategoryData.error) {
                const error = res.error || creditCategoryData.error
                dispatch({
                    type: 'error',
                    error,
                })
            }
        },
        [res],
    )

    React.useEffect(function () {
        if (
            !altArtist &&
            initialFilter[0] === 'Credits' &&
            state.superFacetTotals &&
            !state.superFacetTotals.Credits[0].totalCount
        ) {
            setArtistHasNoReleases(true)
        }
    })

    React.useEffect(function () {
        if (state.anvs[0] === name) {
            dispatch({ type: 'anv', anvs: [] })
        }
    }, [])

    const [showFilters, setShowFilters] = usePersist<boolean>('artist_page_filters')

    React.useEffect(
        function () {
            if (advancedFilterShowState.desktop !== null) {
                setShowFilters(advancedFilterShowState.desktop)
            }
        },
        [advancedFilterShowState.desktop],
    )

    React.useEffect(
        function () {
            if (res.data?.artist?.releases) {
                dispatch({
                    type: 'advancedFilterSelected',
                    advancedFilterSelected,
                    advancedFilterShowState: {
                        desktop: showFilters === false ? showFilters : true,
                        mobile: false,
                    },
                })
            }
        },
        [res.data?.artist?.releases],
    )

    const [, setSortOrder] = usePersist<SortProps['order']>('artist_page_sort_order')
    const [, setShowNumber] = usePersist<number>('artist_page_show_number')
    const [, setViewType] = usePersist<State['view']>('artist_page_view')

    React.useEffect(
        function () {
            setSortOrder(order)
        },
        [order],
    )

    React.useEffect(
        function () {
            if (typeof perPage === 'number') {
                setShowNumber(perPage)
            }
        },
        [perPage],
    )

    React.useEffect(
        function () {
            setViewType(view)
        },
        [view],
    )

    React.useEffect(function () {
        dispatch({ type: 'pagination', page: pageNumberFromUrl, perPage, artistPages: artistPagesData })
    }, [])

    React.useEffect(
        function () {
            safeSetItem('artistPagesData', JSON.stringify(artistPages))
        },
        [artistPages],
    )

    const [searchParam] = useQueryParams('searchParam')

    React.useEffect(
        function () {
            if (searchParam) {
                dispatch({ type: 'search', search: searchParam as string })
            }
        },
        [creditCategoryTotals],
    )

    const [superFilter] = useQueryParams('superFilter')
    const [creditFilter] = useQueryParams('creditFilter')

    React.useEffect(
        function () {
            const updatedOpenSuperFacetState = { ...state.openSuperFacetState }
            if (superFilter && !['Releases', 'Appearances', 'Unofficial', 'Credits'].includes(superFilter as string)) {
                updatedOpenSuperFacetState.Credits = true
                updatedOpenSuperFacetState[superFilter as string] = true
                updatedOpenSuperFacetState[creditFilter as string] = true

                dispatch({
                    type: 'creditState',
                    creditSub: state.creditSub,
                    openSuperFacetState: updatedOpenSuperFacetState,
                })
            } else {
                if (superFilter && creditCategory !== superFilter) {
                    updatedOpenSuperFacetState[creditCategory] = false
                    dispatch({
                        type: 'creditState',
                        creditSub: state.creditSub,
                        openSuperFacetState: updatedOpenSuperFacetState,
                    })
                    return
                }
                updatedOpenSuperFacetState[creditCategory] = true
                dispatch({
                    type: 'creditState',
                    creditSub: state.creditSub,
                    openSuperFacetState: updatedOpenSuperFacetState,
                })
            }
        },
        [creditCategoryTotals],
    )

    const history = useHistory<UseHistoryStateType>()
    /* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */
    const releaseGroupsByHeader = res.data?.artist?.releases?.releaseGroups?.releaseGroupsByHeader

    React.useEffect(
        function () {
            if (altArtist && releaseGroupsByHeader?.length === 0) {
                history.push({
                    pathname: altArtist.siteUrl,
                    state: {
                        name,
                        url,
                    },
                })
            }
        },
        [releaseGroupsByHeader],
    )
}

export const ArtistDiscography = addState(ArtistDiscographyUI, reducer, useInitial, useEffect)

export function ArtistDiscographyUI(props: Props & UIProps): React.ReactElement {
    const {
        filterSelected,
        view,
        discogsId,
        dispatch,
        perPage,
        page,
        order,
        releaseTypes,
        creditName,
        formatFacets,
        labelFacets,
        countryFacets,
        yearFacets,
        countries,
        formats,
        labels,
        years,
        initialFilter,
        creditCategoryTotals,
        superFacetTotals,
        persistedReleaseTypeTotals,
        advancedFilterSelected,
        data,
        loading,
        creditSub,
        tab,
        setTab,
        search,
        searchResultsTotals,
        openSuperFacetState,
        headerIsExpanded,
        anvs,
        advancedFilterShowState,
        error,
    } = props

    const [superFilter] = useQueryParams('superFilter')
    const [subFilter] = useQueryParams('subFilter')
    const [creditFilter] = useQueryParams('creditFilter')

    React.useEffect(function () {
        if (superFilter) {
            dispatch({ type: 'sidebarFilters', filterSelected: [superFilter as string], initialFilter: [''] })
        }
        if (subFilter) {
            dispatch({
                type: 'sidebarFilters',
                filterSelected: [superFilter as string, subFilter as string],
                initialFilter: [''],
            })
        }
        if (creditFilter) {
            dispatch({ type: 'creditName', creditName: creditFilter as string })
        }
    }, [])

    const ref = React.useRef<HTMLDivElement>(null)
    const first = React.useRef(true)
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false)

    React.useEffect(
        function () {
            // we dont want this to fire if the user is in mobile
            // view and isnt selecting a value that closes the sidebar

            if (isSidebarOpen) {
                return
            }

            if (!ref.current) {
                return
            }

            if (first.current) {
                first.current = false
                return
            }

            ref.current.scrollIntoView({ behavior: 'smooth' })
        },
        [page, creditName],
    )

    function toggleSidebar() {
        setIsSidebarOpen(!isSidebarOpen)
    }

    useBlockScroll({ block: isSidebarOpen })

    /* eslint-disable @typescript-eslint/no-unnecessary-condition */
    const releaseGroupsByHeader = data?.artist?.releases?.releaseGroups?.releaseGroupsByHeader

    const itemsOnPage = releaseGroupsByHeader?.reduce(function (acc, releaseGroup) {
        return acc + (releaseGroup?.releaseGroupDescriptions.length ?? 0)
    }, 0)

    // this gives us the dynamic count of all releaseTypes when the user selects an advanced filter.
    const dynamicReleaseTypeTotalCount = data?.artist?.releases?.artistCreditFacets.facets?.filter(
        (superFacet) => superFacet?.superFacetName === 'release_type',
    )[0]?.totalCount

    const totalCounts = getCounts({
        creditCategoryTotals,
        filterSelected,
        initialFilter,
        creditName,
        persistedReleaseTypeTotals,
        searchResultsTotals,
        search,
        dynamicReleaseTypeTotalCount,
        advancedFilterSelected,
    })

    return error ? (
        <div className={css.discographyError}>
            <Trans>Something went wrong in this section of the page.</Trans>
        </div>
    ) : (
        <div className={css.discography}>
            <div className={classNames(css.sidebarContainer, isSidebarOpen && css.mobileSidebar)} ref={ref}>
                <ErrorSection>
                    <ArtistSidebar
                        dispatch={dispatch}
                        creditCategoryTotals={creditCategoryTotals}
                        superFacetTotals={superFacetTotals}
                        persistedReleaseTypeTotals={persistedReleaseTypeTotals}
                        filterSelected={filterSelected[0] ? filterSelected : initialFilter}
                        isSidebarOpen={isSidebarOpen}
                        toggleSidebar={toggleSidebar}
                        itemsOnPage={itemsOnPage}
                        creditName={creditName}
                        creditSub={creditSub}
                        openSuperFacetState={openSuperFacetState}
                    />
                </ErrorSection>
            </div>
            <div className={css.releasesGrid}>
                <ErrorSection>
                    <ArtistFiltersUI
                        dispatch={dispatch}
                        order={order}
                        page={page}
                        perPage={perPage}
                        format={formatFacets}
                        label={labelFacets}
                        country={countryFacets}
                        year={yearFacets}
                        countrySelected={countries}
                        labelSelected={labels}
                        formatSelected={formats}
                        yearSelected={years}
                        advancedFilterSelected={advancedFilterSelected}
                        view={view}
                        itemsOnPage={itemsOnPage}
                        totalCounts={totalCounts}
                        loading={loading}
                        filterSelected={filterSelected[0] ? filterSelected : initialFilter}
                        toggleSidebar={toggleSidebar}
                        creditName={creditName}
                        tab={tab}
                        setTab={setTab}
                        headerIsExpanded={headerIsExpanded}
                        search={search}
                        advancedFilterShowState={advancedFilterShowState}
                        paginationInfo={data?.artist?.releases?.releaseGroups?.paginationInfo}
                    />
                </ErrorSection>
                <ErrorSection>
                    <ArtistReleases
                        discogsId={discogsId}
                        view={view}
                        filterSelected={filterSelected}
                        releaseTypes={releaseTypes}
                        formats={formats}
                        labels={labels}
                        countries={countries}
                        years={years}
                        initialFilter={initialFilter}
                        data={data}
                        loading={loading}
                        itemsOnPage={itemsOnPage}
                        search={search}
                        anvs={anvs}
                        creditName={creditName}
                        paginationInfo={data?.artist?.releases?.releaseGroups?.paginationInfo}
                    />
                </ErrorSection>
                {releaseGroupsByHeader && releaseGroupsByHeader.length > 0 && (
                    <div className={css.filterBar}>
                        <span className={css.one}>
                            <ErrorSection>
                                <Count
                                    page={page}
                                    perPage={perPage}
                                    itemsOnPage={itemsOnPage}
                                    totalCounts={totalCounts}
                                />
                                <Pagination
                                    dispatch={dispatch}
                                    page={page}
                                    perPage={perPage}
                                    totalCounts={totalCounts}
                                    itemsOnPage={itemsOnPage}
                                    paginationInfo={data?.artist?.releases?.releaseGroups?.paginationInfo}
                                />
                            </ErrorSection>
                        </span>
                    </div>
                )}
            </div>
        </div>
    )
}

type ArtistReleasesProps = {
    discogsId: number
    view: string
    filterSelected: string[]
    releaseTypes: string[]
    formats: string[]
    labels: string[]
    countries: string[]
    years: number[]
    initialFilter: string[]
    data: ArtistDiscographyDataQuery | null
    loading: boolean
    itemsOnPage: number | undefined
    search: string
    anvs: string[]
    creditName: string
    paginationInfo: ArtistPaginationInfo['artistPages']
}

export function ArtistReleases(props: ArtistReleasesProps): React.ReactElement | null {
    const {
        discogsId,
        view,
        filterSelected,
        releaseTypes,
        formats,
        labels,
        countries,
        years,
        initialFilter,
        itemsOnPage,
        data,
        loading,
        search,
        anvs,
    } = props

    const creditCategory = filterSelected[0] ? filterSelected[0] : initialFilter[0]

    if (!discogsId) {
        return null
    }

    if (!loading && !itemsOnPage && search) {
        return (
            <div className={css.noSearchResults}>
                <Trans>0 results found when searching for &quot;{search}&quot;</Trans>
            </div>
        )
    }

    if (loading || !data?.artist?.releases || !itemsOnPage) {
        return (
            <div className={css.loadingOverlay}>
                <Loading height='3rem' />
            </div>
        )
    }

    if (!data.artist) {
        // TODO: should we return 404 here?
        return null
    }

    const artistName = data?.artist.name

    /* eslint-disable @typescript-eslint/no-unnecessary-condition */
    const releaseGroupsByHeader = data.artist.releases.releaseGroups?.releaseGroupsByHeader
    if (!releaseGroupsByHeader) {
        return null
    }
    return (
        <div className={css.discographyGrid}>
            {view === 'coversOnly' ? (
                <div className={css.albumCovers} tabIndex={-1}>
                    {releaseGroupsByHeader?.map((releaseType) =>
                        releaseType?.releaseGroupDescriptions.map((release) => (
                            <div key={release?.keyRelease.discogsId}>
                                {/* @ts-expect-error TODO return to type this correctly */}
                                <ReleaseCard {...release?.keyRelease} versionCount={release?.releaseCount} />
                            </div>
                        )),
                    )}
                </div>
            ) : (
                <table className={css.releases}>
                    {releaseGroupsByHeader?.map((releaseType, releaseGroupIndex) => (
                        <>
                            {releaseType?.header.headerName &&
                                ['Releases', 'Appearances', 'Unofficial', 'Credits'].includes(creditCategory) && (
                                    <thead key={releaseType.header.headerName}>
                                        <tr>
                                            <th>
                                                <h2 tabIndex={0}>
                                                    <Trans>{releaseType.header.headerName}</Trans>
                                                </h2>
                                            </th>
                                        </tr>
                                    </thead>
                                )}
                            <tbody>
                                {releaseType?.releaseGroupDescriptions.map((release, i) => (
                                    <>
                                        <ArtistViews
                                            // @ts-expect-error - TODO return to type this correctly
                                            data={release?.keyRelease}
                                            view={view}
                                            creditCategory={creditCategory}
                                            releaseTypes={releaseTypes}
                                            discogsId={discogsId}
                                            versionCount={release?.releaseCount}
                                            formats={formats}
                                            labels={labels}
                                            countries={countries}
                                            years={years}
                                            orderByValue={release?.orderByValue}
                                            artistName={artistName}
                                            anvs={anvs}
                                            search={search}
                                        />
                                        {i === 4 && releaseGroupIndex === 0 && (
                                            <ErrorSection>
                                                <AdDiscography />
                                            </ErrorSection>
                                        )}
                                        {i === 4 && releaseGroupIndex === 1 && (
                                            <ErrorSection>
                                                <AdDiscographyB />
                                            </ErrorSection>
                                        )}
                                    </>
                                ))}
                            </tbody>
                        </>
                    ))}
                </table>
            )}
        </div>
    )
}
